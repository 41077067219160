.c-multi-drag-table .ant-col {
  position: relative;
}
.c-multi-drag-table .inner-col {
  position: relative;
  /*height: 100%;*/
  background: #fff;
  border: 1px solid #f0f0f0;
  padding: 10px;
}
.c-multi-drag-table .ant-table {
  border: 1px solid #f0f0f0;
}
.c-multi-drag-table .ant-table-thead > tr > th,
.c-multi-drag-table .ant-table-tbody > tr > td,
.c-multi-drag-table .ant-table tfoot > tr > th,
.c-multi-drag-table .ant-table tfoot > tr > td {
  padding: 6px 16px;
}
.c-multi-drag-table .ant-table-tbody > tr.ant-table-placeholder > td,
.c-multi-drag-table .ant-table-tbody > tr.ant-table-placeholder:hover > td {
  background: transparent;
}
.c-multi-drag-table .ant-table-tbody > tr.row-item {
  background: #fff;
}
.c-multi-drag-table .ant-table-tbody.is-dragging-over > tr.ant-table-placeholder {
  background: #e6f4ff !important;
  color: #1677ff;
}
.c-multi-drag-table .ant-table-tbody > tr.row-item.row-selected {
  background: #e6f4ff;
  color: #1677ff;
}
.c-multi-drag-table .ant-table-tbody > tr.row-item.row-dragging {
  display: table;
  color: #fff;
  background: #4285f4;
  border: 1px solid #f0f0f0;
}
.c-multi-drag-table .ant-table-tbody > tr.row-item.row-dragging > td {
  border-bottom: 0;
}
.c-multi-drag-table .ant-table-tbody > tr.row-item.row-ghosting {
  opacity: 0.5;
}

.c-multi-drag-table .highlight-table {
  background-color: #e6f7ff;
  border: 2px solid #1890ff;
  transition:
    background-color 0.3s ease,
    border-color 0.3s ease;
}

.c-multi-drag-table .highlight-table .ant-table-container {
  box-shadow: 0 0 10px rgba(24, 144, 255, 0.5);
}
