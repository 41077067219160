.formContainer {
  padding: 2rem;
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
}

.pageHeaderContainer {
  background: #5a5a5a1a !important;
  padding: 8px;
  border-bottom: 1px solid #5a5a5a80;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  border-radius: 8px;
}

.pageHeader {
  background: #5a5a5a1a !important;
  padding: 0 !important;
}

.legend {
  margin-top: -1rem;
  margin-bottom: 1rem;
  opacity: 0.7;
  font-size: 0.75rem;
}
