.formContainer {
  padding: 2rem;
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
}

.legend {
  margin-top: -1rem;
  margin-bottom: 1rem;
  opacity: 0.7;
  font-size: 0.75rem;
}
