.wrapper {
  border: 2px solid rgb(203, 201, 201);
  border-radius: 0.25rem;
  padding: 2rem 2rem 1rem 2rem;
  margin-bottom: 1rem;
  position: relative;
}

.title {
  background-color: inherit;
  overflow: hidden;
  position: absolute;
  top: -0.9rem;
  background-color: white;
  padding: 0 0.875rem;
}
